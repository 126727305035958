.csv-group {
  flex-flow: column;
}

.error {
  color: rgb(245, 131, 131);

}

.modal {
  max-height: 90vh;
}

.modal-body {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.no-payments-message {
  display: flex;
  justify-content: center;
}

.payment {
  margin: 0.5rem 0px;
  display: flex;
  justify-content: center;
  background-color: #32992b;
  border-radius: 4px;
  color: white;
  padding: 3px;
}

.payment-controls {
  margin-left: 1rem;
}

.table-container {
  overflow-x: hidden;
  overflow-y: auto;
}

.table-container th {
  text-align: center;
}

.table-container td {
  text-align: center;
}

.schedule-modifier-buttons {
  display: flex;
}

.schedule-modifier-buttons > .modifier-button {
  margin: 0 1rem;
}

.modifier-button {
  width: 80%;
  margin: 0 auto;
}