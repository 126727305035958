.card {
  min-width: 180px !important;
  margin: 10px;
  max-height: 350px;
}

.group-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.items {
  overflow-y: auto;
  overflow-x: hidden;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-group {
  display: flex;
}

.add-button {
  font-size: large;
  padding: .6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-origin: padding-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: .3em solid transparent;
}

.add-button:hover {
  background-color: rgb(141 99 0);
  border-radius: 50%;
  filter: invert(1);
  cursor: pointer;
}

.add-button:active {
  background-color: rgb(203 148 0);
}

.delete-button {
  font-size: large;
  padding: .6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-button:hover {
  background-color: rgb(141 99 0);
  border-radius: 50%;
  filter: invert(1);
  cursor: pointer;
}

.delete-button:active {
  background-color: rgb(203 148 0);
}

/********************************************************
**************Group Area**************
*********************************************************/

.grid {
  display: grid;
  grid-auto-rows: 1fr;
}

/* XS */

@media (min-width: 0px) {


  .grid {
    grid-template-columns: repeat(1, 100%);
  }

}

@media (max-width: 576px) {
  .desktop-only {
    display: none !important;
  }

  .mb-xs-5 {
    margin-bottom: 5px;
  }

  .pb-xs-5 {
    padding-bottom: 5px;
  }

  .pb-xs-40 {
    padding-bottom: 40px !important;
  }

}

/* SM */

@media (min-width: 576px) {

  .grid {
    grid-template-columns: repeat(2, 50%);
  }

}

@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }
}

/* MD */

@media (min-width: 768px) {

  .grid {
    grid-template-columns: repeat(1, 100%);
  }
}

@media (max-width: 992px) {

}

/* LG */

@media (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media (max-width: 1200px) {}

/* XL */

@media (min-width: 1200px) {

}

@media (max-width: 1400px) {}


/* XXL */

@media (min-width: 1400px) {
  .grid {
    grid-template-columns: repeat(3, calc(100% / 3));
  }
}

/* XXXL */

@media (min-width: 2000px) {
  .grid {
    grid-template-columns: repeat(4, 25%);
  }
}