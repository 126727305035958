nav.navbar {
  flex: 1;
  padding-left: 1rem;
  padding-right: 1rem;
}

.right-collapse {
  justify-content: flex-end;
}


.date-picker {
  background-color: transparent;
  border-style: none;
  color: white;
  text-align: end;
}

.date-picker::-webkit-calendar-picker-indicator {
  margin-left: 1rem;
}