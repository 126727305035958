.line-chart {
  border: solid 1px white;
  background-color: #424242;
  padding: 2px;
}

.container {
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-content: center;
}

.chart-area {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
}

.category-select > *{
  width: 100%;
}

.category-select > div {
  height: 40vh;
  overflow-y: scroll;
}

.month-picker {
  flex: 1;
  border: none;
  padding-left: 1rem;
  color: black;
  background-color: white;
  border-radius: .375rem;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.pie-chart-container {
  max-height: 90%;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    display: flex;
}