.container {
  display: flex;
}

.container>div {
  margin-right: .5rem;
}

.transactions-container {
  display: grid;
  grid-template-columns: repeat(2,50%);
  justify-content: center;
  margin-top: 1rem;
  color: white;
}

.container div[class='dropdown-menu show'] {
  overflow: auto;
  max-height: 33vh;
}


.transaction-stats {
  display: contents;
}

.transaction-stats div:first-child {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  width: 100%;
  margin-bottom: .5rem;
}

.transaction-stats div:nth-child(2)
{
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: .5rem;
}

.transaction-stats > div {
  background-color: #212529;
  padding: 4px 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
}

.transaction-body {
  display: contents;
}


.transaction-body > div:first-child {
  border-top-left-radius: .5rem;
  width: 100%;
}

.transaction-body > div:nth-child(2)
{
  border-top-right-radius: .5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.transaction-body > div:nth-child(3)
{
  border-bottom-left-radius: .5rem;
  margin-bottom: 1rem;
}

.transaction-body > div.category-container:last-child {
  border-bottom-right-radius: .5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.transaction-body > div {
  background-color: #212529;
  padding: 4px 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
}

.transaction-body div.category-container {
  overflow: visible;
}

.transaction-body div.category-container > div{
  width: 100%;
}

.category-container button{
  width: 100%;
  background: none;
  border: none;
  padding: 0px .5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.category-container div[class='dropdown-menu show'] {
  overflow: auto;
  max-height: 33vh;
}
