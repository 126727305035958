@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "./themes/darkly.min.css";

html, body, #root {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overscroll-behavior: contain;
}

#root {
  display: flex;
  flex-flow: column;
}

#main_layout {
  flex: 1 1;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}