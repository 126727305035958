.nav-container-row {
  margin-top: 2px;
  border-top: solid white 1px;
}

.nav {
  width: 100%;
}

.content-row {
  overflow-y: scroll;
}

.content {
  overflow: hidden;
  transition: ease-in-out .35s height;
  height: auto;
  flex: 1;
  display: flex;
}