.row {

}

.row .col {
  padding-left: 0px;
  padding-right: 0px;
}

.col.left {
  
}

.col.right > nav {
  background-color: #282b2e !important;
}